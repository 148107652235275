import { createRouter, createWebHashHistory } from 'vue-router'
import RegisterComponent from '../views/RegisterComponent.vue' // Import the register component
import SearchComponent from '../components/SearchComponent.vue';
const routes = [
  {
    path: '/',
    name: 'home',
    component: SearchComponent
  },
  {
    path: '/register', // Add a path for registration
    name: 'register',
    component: RegisterComponent // Set the component to be the RegisterComponent
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
