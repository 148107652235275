<template>
  <div class="register-container">
    <h1>注册新用户</h1>
    <form @submit.prevent="register" class="register-form">
      <!-- 选择注册方式 -->
      <div class="register-method">
        <label>
          <input type="radio" value="email" v-model="registerMethod" /> 使用邮箱注册
        </label>
        <label>
          <input type="radio" value="phone" v-model="registerMethod" /> 使用手机注册
        </label>
      </div>
      
      <!-- 邮箱输入，当选择邮箱注册时显示 -->
      <div v-if="registerMethod === 'email'" class="form-group">
        <label for="email">邮箱：</label>
        <input id="email" v-model="email" type="email" placeholder="请输入您的邮箱" required>
      </div>
      
      <!-- 手机号码输入，当选择手机注册时显示 -->
      <div v-if="registerMethod === 'phone'" class="form-group">
        <label for="phone">手机号码：</label>
        <select v-model="selectedCountryCode" class="country-code">
          <option v-for="code in countryCodes" :key="code" :value="code">{{ code }}</option>
        </select>
        <input id="phone" v-model="phone_number" type="tel" placeholder="请输入您的手机号" required>
      </div>
      
      <div class="form-group">
        <label for="username">用户名：</label>
        <input id="username" v-model="username" required placeholder="设置用户名">
      </div>
      <div class="form-group">
        <label for="password">密码：</label>
        <input id="password" v-model="password" type="password" required placeholder="设置密码">
      </div>
      <div class="form-group">
        <label for="confirmPassword">确认密码：</label>
        <input id="confirmPassword" v-model="confirmPassword" type="password" required placeholder="再次输入密码">
      </div>

       <!-- 显示错误消息 -->
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

      <button type="submit" class="submit-btn">注册</button>
    </form>
  </div>
</template>

<script>
import axios from './../axios'; // 确保 axios 已配置好，可以发送请求

export default {
  data() {
    return {
      email: '',
      phone_number: '',
      selectedCountryCode: '+86',
      countryCodes: ['+1', '+86', '+91', '+44', '+81', '+49', '+33', '+61', '+55', '+7', '+82', '+31', '+39', '+34', '+62'],
      username: '',
      password: '',
      confirmPassword: '',
      registerMethod: 'email',
      errorMessage: '',
      isLoading: false
    };
  },
  methods: {
    validateForm() {
      this.errorMessage = '';
      if (!this.username) {
        this.errorMessage = '用户名不能为空。';
        return false;
      }
      if (this.registerMethod === 'email' && !this.email) {
        this.errorMessage = '邮箱不能为空。';
        return false;
      }
      if (this.registerMethod === 'phone' && !this.phone_number) {
        this.errorMessage = '手机号码不能为空。';
        return false;
      }
      if (this.password.length < 6) {
        this.errorMessage = '密码必须至少包含6个字符。';
        return false;
      }
      if (this.password !== this.confirmPassword) {
        this.errorMessage = '两次输入的密码不匹配。';
        return false;
      }
      return true; // All validations passed
    },
    register() {
      if (this.validateForm()) {
        this.isLoading = true;
        const postData = {
          username: this.username,
          password: this.password,
          registerMethod: this.registerMethod,
          email: this.email,
          phone_number: this.selectedCountryCode + this.phone_number
        };

        axios.post('http://127.0.0.1:8000/api/register/', postData)
          .then(response => {
            console.log('Registration successful', response);
            alert('注册成功！');
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Registration failed:', error);
            this.errorMessage = '注册失败: ' + (error.response.data.error || '未知错误');
            this.isLoading = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.register-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h1 {
  text-align: center;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display:block;
  margin-bottom: 5px;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="tel"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.country-code {
  width: auto;
  display: inline-block;
  margin-right: 10px;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.register-method {
  margin-bottom: 20px;
}
</style>
