// src/App.vue 或其他父组件文件

<template>
  <div id="app">
    <!-- 任何你想始终显示的全局组件，比如导航栏 -->
    <search-component v-if="!$route.name === 'register'"></search-component>
    <!-- 路由视图：路由匹配的组件将在这里渲染 -->
    <router-view></router-view>
    <!-- 其他全局组件 -->
  </div>
</template>

<script>
import SearchComponent from './components/SearchComponent.vue'

export default {
  name: 'App',
  components: {
    'search-component': SearchComponent
  }
}
</script>

