// src/axios.js
import axios from 'axios';

// 创建 Axios
const axiosInstance = axios.create({
    baseURL: 'http://newbie.art/api/',
    withCredentials: true  
});


function getCsrfToken() {
    let token = null;
    const cookies = document.cookie.split(';');
    const csrfCookie = cookies.find(cookie => cookie.trim().startsWith('csrftoken='));
    if (csrfCookie) {
        token = csrfCookie.split('=')[1];
    }
    return token;
}


axiosInstance.interceptors.request.use(config => {
    const csrfToken = getCsrfToken();
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
