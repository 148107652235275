<template>
    <div id="app">
        <div id="observedElement"></div>
    <header ref="header">
      <img src="/ComfyUI_00305_.webp" alt="Logo" class="logo" />
      <!-- Floating toggle button within the sidebar -->
      <button @click="toggleSidebar" class="toggle-button" :style="{ left: sidebarVisible ? '230px' : '0px' }">{{ sidebarVisible ? '<<' : '>>' }}</button>
             <div class="dimensions-panel" v-show="showDimensions" ref="dimensionsPanel">
        <ul>
          <li v-for="dimension in dimensions" :key="dimension.id"
          @click="selectDimension(dimension.name)"
          :class="{'selected': dimension.name === searchQuery}">
          {{ dimension.name }}
          </li>
        </ul>
      </div>
    </header>

       <div class="main-content">
      
           <aside class="sidebar"  ref="sidebar" v-show="sidebarVisible">
              <div v-if="!isLoggedIn" class="login-controls">
          <input v-model="username" placeholder="Username"  />
          <input v-model="password" type="password" placeholder="Password"  />
                  <!-- <button @click="login">Login</button> -->
                  <button @click="showAlert">Login</button>
          <button @click="showAlert">Register</button>
        </div>
               <div v-else class="logout-controls">
          <button @click="logout">登出</button>
        </div>
        <button @click="toggleDimensions">Show Dimensions</button> <!-- 新增按钮用于切换维度显示 -->
              <div class="social-media-links">
          <div class="social-link">
            <a href="https://www.xiaohongshu.com/user/profile/65ec1e620000000005008aee?xhsshare=WeixinSession&appuid=65ec1e620000000005008aee&apptime=1713440020" target="_blank" rel="noopener noreferrer" aria-label="小红书">
              🔗作品鉴赏
            </a>
          </div>
          <div class="social-link">
            <a href="https://space.bilibili.com/176812973" target="_blank" rel="noopener noreferrer" aria-label="bilibili">
              🔗Bilibili
            </a>
          </div>
          <div class="social-link">
            <a href="https://www.youtube.com/@Newbie.Art_home" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
              🔗YouTube
            </a>
          </div>


          <div class="donation-section" @click="toggleDonationQR">
            <span class="donation-text">🍵 中式投喂</span>
            
            
            </div>
            <div v-show="showDonationQR" id="paypal-container" style="display:none;"></div>
            <div v-if="showDonationQR">
              <img src="@/images/wechat-qr.webp" alt="微信收款码" class="donation-qr">
             
            </div>
            
          </div>
          
          <div id="paypal-container-9F8325QDSPDNN"></div>
          <div id="paypal-button-container" style="display: none;"></div>
          <div v-if="showThankYou" class="thank-you-message">
              感谢您的支持！




        </div>
      
      </aside>
     
      

          <div class="search-area">
             <div class="controls">
          <input v-model="searchQuery" placeholder="Enter keywords" aria-label="Search Term" @keyup.enter="search" ref="searchBox" style="font-size: 1.3em;">
          <button @click="search">Search</button>
        </div>
             <div class="search-message" v-if="searchMessage">{{ searchMessage }}</div>


             <div class="results-and-keywords">
                <div class="search-results" v-if="results.length">
            <ul>
              <li v-for="(result, index) in results" :key="index">
                          "{{ result.dimension_name }}":
                <span v-for="keyword in result.keywords.split(', ')" :key="keyword"
                      @click="toggleKeywordSelection(keyword.split('/')[0])"
                      :class="{ 'selected': selectedKeywords.includes(keyword.split('/')[0]) }">
                    {{ keyword.split('/')[0] }} ({{ keyword.split('/')[1] || '无翻译' }})
                </span>
              </li>
            </ul>
          </div>
          <div class="selected-keywords-label">----------------------------------------------------</div>
          <div class="selected-keywords-label">Selected Keywords:</div>
                <div class="keywords-actions">
            
            <div class="selected-keywords-list">
              <span v-for="(keyword, index) in selectedKeywords" :key="index"
                    @click="toggleKeywordSelection(keyword)"
                    class="selected-keyword">
                {{ keyword }}
              </span>
            </div>
            
          </div>
          <div class="action-buttons">
              <button @click="copySelectedKeywordsToClipboard">Copy to clipboard！</button>
              <button @click="clearSelectedKeywords">empty</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable no-redeclare */

import axios from 'axios';


/* global paypal */
export default {
  data() {
    return {
           username: '',
      password: '',
      email: '', 
      phone_number: '', 
      searchQuery: '',
      sidebarVisible: true,  
      results: [],
      selectedKeywords: [],
      isLoggedIn: false,
      isLoading: false,
      errorMessage: '',
      searchMessage: '', 
      showRegisterFields: false, 
      showDonation: false,  
      showDonationQR: false,
      persistentShow: false,  
      wechatQR: require('@/images/wechat-qr.jpg'),  
      paypalQR: require('@/images/paypal-qr.jpg'),   
      showThankYou: false, 
      showDimensions: false, 
      dimensions: [], 
      csrfToken: '',  
      resizeObserver: null
      
    };
  },
  methods: {
      handleDocumentClick(event) {
      const header = this.$refs.header;
      const dimensionsPanel = this.$refs.dimensionsPanel;
      const searchBox = this.$refs.searchBox; 
      if ((header.contains(event.target) && !dimensionsPanel.contains(event.target) || searchBox.contains(event.target)) && this.showDimensions) {
        this.showDimensions = false; 
      }
    },
        showAlert() {
      alert("Explore our free tools without signing up. Please note that some features are currently under development and will be available soon—stay tuned!");
    },
    selectDimension(dimensionName) {
      this.searchQuery = dimensionName; 
      this.search(); 
    },
    loadPayPalScript() {
      return new Promise((resolve, reject) => {
        if (typeof paypal === 'undefined') {
          const script = document.createElement('script');
          script.src = "https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&components=buttons";
          script.onload = () => {
         // console.log("PayPal SDK loaded successfully.");
            resolve();
          };
          script.onerror = reject;
          document.head.appendChild(script);
        } else {
          resolve();
        }
      });
    },
    handlePayPalDonation(event) {
      event.preventDefault();  
      this.showDonation = true; 
      paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '5' 
              }
            }]
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then(function(details) {
         // console.log(details); 
            alert(`感谢您的捐赠，${details.payer.name.given_name}！`);
          });
        }
      }).render('#paypal-button-container');  
    },
 
    showThankYouMessage() {
      this.showThankYou = true; 

      
      setTimeout(() => {
        this.showThankYou = false;
      }, 1000); 
    },
    handleMouseLeave() {
      if (!this.persistentShow) {
        this.showDonationQR = false;
      }
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    toggleDimensions() {
      this.showDimensions = !this.showDimensions;
      if (!this.dimensions.length) {
        this.loadDimensions(); 
      }
    },
    loadDimensions() {
      axios.get('https://newbie.art/api/dimensions/')
        .then(response => {
          this.dimensions = response.data;
       // console.log('Loaded dimensions:', this.dimensions);  
        })
        .catch(error => {
          console.error('加载维度数据失败:', error);
        });
    },
    togglePersistentShow() {
      this.persistentShow = !this.persistentShow;
      this.showDonationQR = this.persistentShow; 
    },
    toggleDonationQR() {
      this.showDonationQR = !this.showDonationQR;
      
    },
    
    login() {
      this.isLoading = true;
      axios.post('https://newbie.art/api/login/', {
        username: this.username,
        password: this.password
      }, {
        headers: {
          'X-CSRFToken': this.csrfToken  
        }
      })
      .then(() => {
        this.isLoggedIn = true;
        
      })
      .catch(error => {
        this.errorMessage = '登录失败: ' + error.response.data;
        
      });
    },
    logout() {
      this.isLoggedIn = false;
      
    },
    goToRegister() {
            this.$router.push({ name: 'register' });
    },
    toggleRegister() {
      this.showRegisterFields = !this.showRegisterFields; 
    },
    
    register() {
   // console.log('Register method called'); 
            if (!this.username || !this.password || !this.email || !this.phone_number) {
	this.errorMessage = '请填写完整的注册信息';
        return;
      }
      this.isLoading = true;
      axios.post('https://newbie.art/api/register/', {
        username: this.username,
        password: this.password,
        email: this.email,
        phone_number: this.phone_number 
        
      })
      .then((response) => {
        void(response);
       // console.log('Registration successful', response);
        this.isLoggedIn = true;
        
        
        
        this.username = '';
        this.password = '';
        this.email = '';
        this.phone_number = '';
        this.searchMessage = 'Successful registration, please log in.';
      })
      .catch(error => {
        console.error('Registration failed:', error.response ? error.response.data : error);
        this.errorMessage = '注册失败: ' + (error.response ? error.response.data : error.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    
    search() {
      if (!this.searchQuery.trim()) {
        this.searchMessage = 'Please enter a search term';
        return;
      }  

      
      this.searchMessage = '';
      this.isLoading = true;
      axios.get(`https://newbie.art/api/search/?keyword=${this.searchQuery}`)
      .then(response => {
        this.isLoading = false;
        if(response.data.results && response.data.results.length > 0) {
          this.results = response.data.results;
          this.searchMessage = '';
        } else {
          this.searchMessage = response.data.error || "The query can't find the relevant dimension!";
          this.results = []; 
        }
      })
      .catch(error => {
        this.isLoading = false;
        
        if (error.response && error.response.status === 429) {
          this.searchMessage = error.response.data.error; 
        } else {
          
          this.searchMessage = 'Network or server error';
        }
      });
    },
    checkOverlap() {
      const sidebar = this.$refs.sidebar;
      const searchBox = this.$refs.searchBox;
      if (sidebar && searchBox) {
        const sidebarRect = sidebar.getBoundingClientRect();
        const searchBoxRect = searchBox.getBoundingClientRect();
        if (sidebarRect.right >= searchBoxRect.left) {
          this.sidebarVisible = false;  
        } else {
          this.sidebarVisible = true;   
        }
      }
    },
    
    toggleKeywordSelection(keyword) {
      const englishKeyword = keyword.split('/')[0]; 
      const index = this.selectedKeywords.indexOf(englishKeyword);
      if (index === -1) {
        this.selectedKeywords.push(englishKeyword);
      } else {
        this.selectedKeywords.splice(index, 1);
      }
    },
    
    copySelectedKeywordsToClipboard() {
      const text = this.selectedKeywords.join(',');
      navigator.clipboard.writeText(text)
      .then(() => {
        this.errorMessage = '关键词已复制到剪贴板！';
      })
      .catch(err => {
        this.errorMessage = '复制文本错误: ' + err.message;
      });
    },
    
    clearSelectedKeywords() {
      this.selectedKeywords = [];
    }
  },
  mounted() {
    if (this.$refs.searchBox) {
      this.$refs.searchBox.addEventListener('click', this.handleDocumentClick);
    }
    if (this.$refs.header) {
      this.$refs.header.addEventListener('click', this.handleDocumentClick);
    }
    document.title = "Newbie Art";  

    this.$nextTick(() => {
      const tokenElement = document.getElementById('csrf');
      if (tokenElement) {
        this.csrfToken = tokenElement.value;
      } else {
        console.error('CSRF token element not found');
      }
    });
    const observedElement = document.getElementById('observedElement');
    if (observedElement) {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          window.requestAnimationFrame(() => {
            void(entry);
           // console.log('Element size changed');
            
            
           // console.log('New size:', entry.contentRect.width, 'x', entry.contentRect.height);
          });
        }
      });
      this.resizeObserver.observe(observedElement);
    }
    this.loadDimensions();
   // console.log('Component is now mounted!');
    if (this.results.length === 0) { // eslint-disable-next-line no-empty
   //   console.log('No search results are loaded at mount.');
    }
    this.loadPayPalScript().then(() => {
      paypal.HostedButtons({
        hostedButtonId: "9F8325QDSPDNN"
      }).render("#paypal-container-9F8325QDSPDNN");
    }).catch(error => {
      console.error("PayPal SDK could not be loaded: ", error);
    });
    
    window.addEventListener('resize', this.checkOverlap);
    
    this.checkOverlap();
  },
  beforeUnmount() {
    if (this.$refs.searchBox) {
      this.$refs.searchBox.removeEventListener('click', this.handleDocumentClick);
    }
    if (this.$refs.header) {
      this.$refs.header.removeEventListener('click', this.handleDocumentClick);
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}


</script>


<style scoped>
/* Vue root element style */
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh;
  padding-top: 220px; 
}

/* Header style */
header {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000; 
  background: #FFF; 
}

/* Logo image style */
.logo {
  max-height: 200px; 
  margin-top: 10px; 
  margin-bottom: 10px; 
}

/* Sidebar style */
.sidebar {
  position: fixed; 
  left: 0;
  top: 0;
  width: 200px;
  height: 100%;
  background-color: #f3f3f3;
  padding: 20px;
  overflow-y: auto; 
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  z-index: 1000; 
  flex-grow: 1; 
}

input {
  width: 100%; 
  padding: 8px;
  margin-top: 10px; 
  border: 1px solid #ddd; 
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1); 
}

.main-content {
  display: flex;
  justify-content: center; 
}

/* Search area style */
.search-area {
  position: fixed; 
  top: Xpx; 
  z-index: 500; 
  width: 100%;
  max-width: 800px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 500; 
}

/* Controls area style, includes input box and button */
.controls {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/* Adjust input box style */
.controls input {
  flex-grow: 1; 
  height: 40px; 
  width: auto;
  padding: 8px 10px; 
  border: 1px solid #ccc; 
  border-right: none; 
  border-radius: 4px 0 0 4px; 
}



/* Search results and keyword action container style, left-right layout */
.results-and-keywords {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: space-between;
  flex-wrap: wrap; 
  margin-top: 20px; 
  width: 100%;
  z-index: 500; 
  padding: 10px; 
}

/* Search results style */
.search-results {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); 
  grid-gap: 10px; 
  max-height: 250px; 
  overflow-y: auto; 
}


.search-results ul {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  
}


.search-results li {
  display: flex; 
  flex-wrap: wrap; 
  align-items: center; 
  margin-bottom: 10px; 
}


.search-results span {
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px; 
  transition: background-color 0.3s ease;
  border-radius: 5px;
  padding: 2px 5px;
  border: 1px solid #007BFF;
  background-color: #ffffff; 
  margin-right: 5px; 
  margin-bottom: 5px; 
}

.search-status {
  margin-top: 10px; 
  color: #d9534f; 
}


.keywords-actions {
  width: 100%; 
  display: flex;
  
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  
  
  max-height: 250px; 
  overflow-y: auto; 
  border-radius: 20px;
}

.selected-keywords-list{
  margin-top: 20px; 
  margin-bottom: 20px; 
  max-height: 100px;
}
.selected-keywords-list span{
  
  border: 1px solid #007BFF;
  border-bottom: 10px;
  border-top: 10px;
  padding: 2px 5px;

}



.error {
  color: red;
  margin-top: 5px;
}


li {
  margin-bottom: 10px;
}


span {
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px; 
  transition: background-color 0.3s ease;
  border-radius: 5px;
  padding: 2px 5px;
  border: 1px solid #007BFF;
}



.span:hover, .selected {
  background-color: #007BFF;
  color: rgb(229, 112, 33);
  

}

button:hover {
  background-color: #0056b3; 
}

button:active {
  background-color: #ce1616; 
  
}


button {
  padding: 10px 15px; 
  margin: 10px 10px 10px 5px; 
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}




.social-media-links {
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-top: 20px; 
}

.social-link {
  margin-bottom: 10px; 
}

.social-media-links a img {
  width: 40px; 
  height: 40px; 
  margin: 5px; 
}
.social-link a, .donation-qr {
  width: auto;
  height: auto;
  margin: 5px;
  transition: transform 0.3s ease;
}
.social-link a:hover, .donation-qr:hover {
  transform: scale(1.1);  
}

.social-media-links a {
  display: inline-block;
  text-decoration: none; 
}

/* Donation section styling */
.donation-section {
  cursor: pointer;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  position: relative;
  
}

.donation-text {
  margin: 0;
  font-size: 16px;
  color: #333;
  
}

.donation-qr {
  max-width: 100px;  
  margin-top: 8px;  
  display: block;  
  margin-left: auto;  
  margin-right: auto;
}

.donation-section img {
  width: 80%; 
  max-width: 200px; 
  margin-bottom: 10px; 
}

.donation-section p {
  text-align: center;
  color: #333; 
  font-size: 14px; 
}

.toggle-button {
  position: fixed;
  top: 0; 
  left: 250px; 
  z-index: 10;
  padding: 8px 16px;
  color: #007bff;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.dimensions-panel {
  position: fixed;
  top: 0;
  left: 200px; 
  width: 300px; 
  height: 100vh;
  background-color: #f9f9f9;
  overflow-y: auto; 
  z-index: 10;
}


.dimensions-panel {
  position: fixed;
  left: 230px;

  height: calc(100vh - 100px); 
  background-color: #f9f9f9;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); 
  border-radius: 8px; 
  border: 1px solid #ddd; 
}

.sidebar::-webkit-scrollbar {
    width: 10px;  
    background-color: #f9f9f9; 
}

.sidebar::-webkit-scrollbar {
    width: 10px;  
    background-color: #f9f9f9; 
}
.container {
  display: flex;
  flex-direction: column;  
  justify-content: space-between;  
}

.button-container {
  margin-bottom: auto;  
}
</style>




